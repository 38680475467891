import {Box, Container, CssBaseline, Grid, Link, Typography} from '@mui/material';
import {Outlet} from 'react-router-dom';
import React from 'react';
// import {useDetectAdBlock} from "../../hooks/useDetectAdBlock";
import Alternative from "./Alternative";
import Header from "./Header";
import Sidebar from "./Sidebar";
// import {useDetectAdBlock} from "../../hooks/useDetectAdBlock";
import Footer from "./Footer";
// import FootyLight from "../../views/Match/FootyLight";

// const Sidebar = Loadable(lazy(() => import('./Sidebar')));
// const Header = Loadable(lazy(() => import('./Header')));
// const Footer = Loadable(lazy(() => import('./Footer')));
// const Alternative = Loadable(lazy(() => import('./Alternative')));
// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
    // const adBlockDetected = useDetectAdBlock();
    return (
        <Box>
            <CssBaseline />
            <Header />
            <Alternative/>
            {/*<Box minHeight="300px" id="M820537ScriptRootC1340029"></Box>*/}
            {/*<div align={"center"}>
                <Box my={2} component={"a"} href={"https://ufc285.mmastreams100.com/"}>
                    <img src={"https://i.imgur.com/DoCEQ6F.png"}/>
                </Box>
            </div>*/}
            {/*<div align="center" id="div-gpt-ad-8176806-7"></div>*/}
            {/*KODI PER BANNER<div align={"center"}>
                <Box my={2} component={"a"} href={"https://rollerads.com/?utm_source=soccerstreams&utm_medium=banner_pubs&utm_campaign=nik"}>
                    <img alt="awkngtv" src={"/roller.gif"}/>
                </Box>
            </div>*/}
            
            <Container maxWidth='xl'>
                {/*<FootyLight />*/}
                <Grid container spacing={2}>
                    <Sidebar key='left' id={0} />
                    <Grid item xs={12} md={6}>
                        {/*adBlockDetected ? <Dialog open={adBlockDetected}>
                            <DialogTitle>
                                {"Adblock Detected"}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    Uh oh. Looks like you are using an ad blocker .
                                    We charge advertisers instead of our audience .
                                    Please whitelist our site to get access to our content
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => window.location.reload()} autoFocus>
                                    Agree
                                </Button>
                            </DialogActions>
                        </Dialog> : '' */}
                        <Outlet />
                        <div align="center" id="nativefluid"></div>
                    </Grid>
                    <Sidebar key='right' id={1} />
                </Grid>
            </Container>
            <Footer />
            <Typography variant="body2" color="text.secondary" align="center">
                {'Copyright © '}
                <Link color="inherit" href="https://redly.dev/">
                    Redly
                </Link>{' '}
                {new Date().getFullYear()}.
            </Typography>
            {/*<img src="https://go.nordvpn.net/aff_i?offer_id=15&file_id=25&aff_id=11102" width="0" height="0" style={{position:"absolute",visibility:"hidden"}} border="0" />*/}
            {/*<img src="https://namecheap.pxf.io/i/3488605/1187124/5618" height="0" width="0" style={{position:"absolute",visibility:"hidden"}} border="0" />
            <img src="https://namecheap.pxf.io/i/3488605/1159290/5618" height="0" width="0" style={{position:"absolute",visibility:"hidden"}} border="0" />
            <img src="https://namecheap.pxf.io/i/3488605/1159277/5618" height="0" width="0" style={{position:"absolute",visibility:"hidden"}} border="0" />
            <img src="https://namecheap.pxf.io/i/3488605/1159272/5618" height="0" width="0" style={{position:"absolute",visibility:"hidden"}} border="0" />
            <img src="https://namecheap.pxf.io/i/3488605/1159281/5618" height="0" width="0" style={{position:"absolute",visibility:"hidden"}} border="0" />*/}
        </Box>
    );
};

export default MainLayout;
